<template>
  <div>
    <div class="uploadImgCmpt">
      <!-- 上传组件 -->
      <input ref="input" type="file" @change="change" style="display:none" accept="image/*" />
      <div class="uploadBox" @click="uploadImg">
        <!-- 未上传图片的封面 -->
        <div class="cover1" v-show="!loading && !uploaded && !picUrl">
          <img src="@/assets/image/upload.png" />
        </div>
        <!-- 上传中的loading -->
        <i class="cover2 el-icon-loading" v-show="loading && !uploaded"></i>
        <!-- 上传完毕的封面 -->
        <el-image class="img" fit="contain" :src="picUrl" v-show="uploaded || picUrl" :preview-src-list="[picUrl]">
        </el-image>
        <!-- 删除按钮 -->
        <!-- <i class="delete el-icon-delete" v-show="uploaded" @click.stop="deleteImg"></i> -->
        <!-- 只要picUrl有值就可以删除图片 -->
        <i class="delete el-icon-delete" v-show="picUrl && !isDelete" @click.stop="deleteImg"></i>
        <i class="edit el-icon-edit" v-show="picUrl && !isDelete" @click.stop="editAgain"></i>
      </div>
    </div>
    <el-dialog :visible.sync="dialogVisible" append-to-body v-if="dialogVisible" :close-on-click-modal="false"
      :close-on-press-escape="false" :show-close="false" :modal="isModel">
      <span slot="title" class="dialog-title">
        图片裁剪
      </span>
      <div class="cropper" style="text-align:center;width: auto;height:500px;padding: 30px 20px;">
        <vueCropper ref="cropper" :img="option.img" :outputSize="option.outputSize" :outputType="option.outputType"
          :info="option.info" :canScale="option.canScale" :autoCrop="option.autoCrop"
          :autoCropWidth="option.autoCropWidth" :autoCropHeight="option.autoCropHeight" :fixed="option.fixed"
          :fixedNumber="option.fixedNumber" :full="option.full" :fixedBox="option.fixedBox" :canMove="option.canMove"
          :canMoveBox="option.canMoveBox" :original="option.original" :centerBox="option.centerBox"
          :height="option.height" :infoTrue="option.infoTrue" :maxImgSize="option.maxImgSize" :enlarge="option.enlarge"
          :mode="option.mode" @realTime="realTime" @imgLoad="imgLoad" @getCavasImg="getCavasImg">
        </vueCropper>
      </div>
      <div slot="footer" style="text-align: center;">
        <el-button size="mini" icon="el-icon-close" :disabled="isSubmit" @click="dialog()">取 消</el-button>
        <el-button size="mini" type="danger" :disabled="isSubmit" icon="el-icon-zoom-in" @click="changeScale(1)">放大
        </el-button>
        <el-button size="mini" type="danger" :disabled="isSubmit" icon="el-icon-zoom-out" @click="changeScale(-1)">缩小
        </el-button>
        <el-button size="mini" type="danger" :disabled="isSubmit" @click="rotateLeft">↺ 左旋转</el-button>
        <el-select v-model="rotate" class="roate">
          <el-option label="角度：15" :value="15"></el-option>
          <el-option label="角度：30" :value="30"></el-option>
          <el-option label="角度：45" :value="45"></el-option>
          <el-option label="角度：90" :value="90"></el-option>
        </el-select>
        <el-button size="mini" type="danger" :disabled="isSubmit" @click="rotateRight">↻ 右旋转</el-button>
        <el-button size="mini" type="primary" :disabled="isSubmit" :icon="submitIcon" @click="uploadImgs('blob',true)">
          {{ submitText }}</el-button>
          <el-button size="mini" type="primary" v-if="isShowNoOcr" :disabled="isSubmit" :icon="submitIcon" @click="uploadImgs('blob',false)">
          {{ submitTextNoOcr }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import VueCropper from "../vue-cropper/vue-cropper";
import { upLoadImgByUrl } from "@/api/common/common";
import { _dataURLtoBlob } from "@/utils/utils"
export default {
  components: {
    VueCropper
  },
  props: {
    isModel: true, //是否显示遮罩层
    loading: false, //是否正在上传图片
    uploaded: false, //是否上传完毕
    isDelete: false, // 控制是否有删除按钮  默认有删除按钮(同时控制二次编辑按钮，能删除就能编辑，不能删除也代表不能编辑)
    picUrl: "",
    fileType: {
      type: String,
      default: "Other",
    },
    coverDesc: {
      default: () => {
        return "图片格式只支持：PNG、JPEG、JPG、X-ICON";
      },
    },

    //是否显示仅上传图片按钮
    isShowNoOcr:{
      type:Boolean,
      default:false
    }
  },
  data() {
    return {
      showDialog: false, //是否显示图片
      dialogVisible: false, // 图片裁剪弹窗
      // 裁剪组件的基础配置option
      option: {
        img: "", //裁剪图片的地址
        outputSize: 1, //裁剪生成图片的质量(可选0.1 - 1)
        outputType: "png", //裁剪生成图片的格式（jpeg || png || webp）
        info: false, //图片大小信息
        canScale: true, //图片是否允许滚轮缩放
        autoCrop: true, //是否默认生成截图框
        autoCropWidth: 650, //默认生成截图框宽度
        autoCropHeight: 450, //默认生成截图框高度
        // fixed: true,         //是否开启截图框宽高固定比例
        fixedNumber: [1.5, 1], //截图框的宽高比例
        full: false, //false按原比例裁切图片，不失真
        fixedBox: false, //固定截图框大小，不允许改变
        canMove: true, //上传图片是否可以移动
        canMoveBox: true, //截图框能否拖动
        original: false, //上传图片按照原始比例渲染
        centerBox: false, //截图框是否被限制在图片里面
        height: true, //是否按照设备的dpr 输出等比例图片
        infoTrue: false, //true为展示真实输出图片宽高，false展示看到的截图框宽高
        maxImgSize: 3000, //限制图片最大宽度和高度
        enlarge: 1, //图片根据截图框输出比例倍数
        mode: "100% 100vh", //图片默认渲染方式
      },
      previews: '',
      isSubmit: false,
      submitIcon: "el-icon-upload",
      submitText: "上传并识别图片",
      submitTextNoOcr:"仅上传图片",
      rotate: 90,
      cavasImg: ''
    };
  },
  methods: {
    getCavasImg(val) {
      this.cavasImg = val
    },
    dialog() {
      this.dialogVisible = false;
      this.isSubmit = false;
      this.submitIcon = "el-icon-upload";
      this.submitText = "上传图片";
      this.$refs["input"].value = null;
    },
    //初始化函数
    imgLoad(msg) {
      // console.log("工具初始化函数=====" + msg);
    },
    //图片缩放
    changeScale(num) {
      num = num || 1;
      this.$refs.cropper.changeScale(num);
    },
    //向左旋转
    rotateLeft() {
      this.$refs.cropper.rotate = this.$refs.cropper.rotate - (this.rotate / 90);
    },
    //向右旋转
    rotateRight() {
      this.$refs.cropper.rotate = this.$refs.cropper.rotate + (this.rotate / 90);
    },
    //实时预览函数
    realTime(data) {
      this.previews = data;
    },
    //点击图片
    uploadImg() {
      // 如果正在上传
      if (this.loading) return;
      // 如果已经有图片则不让点击  需先删除再上传
      if (this.picUrl) return;
      this.$refs["input"].click();
    },
    // 上传到服务器
    uploadImgs(type,isocr) {
      let _this = this
      this.isSubmit = true;
      this.submitIcon = "el-icon-loading";
      this.submitText = "上传中....";
      
      try{
        _this.$emit("changeIsOcrImg",isocr);
      }catch(error){
      }

      if (type === "blob") {
        try {
          _this.$refs.cropper.getCropBlob((data) => {
            let imgUri = _this.cavasImg; // 获取生成的图片的url
            if (imgUri) {
              let aTime = new Date().getTime();
              let fileName = aTime + "." + data.type.substr(6); // 给文件名加后缀          
              let file = new window.File([_dataURLtoBlob(imgUri)], fileName, { type: data.type }); // blob转fil
              // //兼容ie
              _this.$emit("update:file", file);
              _this.$emit("change");
              //防止上传相同的图片  change事件不触发
              _this.$refs["input"].value = null;
            } else {
              this.dialog()
              this.$message.error("上传失败，请重试")
            }
          });
        } catch (error) {
          this.isSubmit = false;
          this.submitIcon = "el-icon-upload";
          this.submitText = "上传图片";
          this.$refs["input"].value = null;
          this.option.img = "";
          this.$message.error("上传失败，请重试")
        }
      }
    },
    //实际上传图片逻辑
    change(e) {
      let files = e.srcElement.files[0] || e.target.files[0];
      let isLt5M = files.size / 1024 / 1024 < 5;
      if (!isLt5M) {
        this.$message.error("上传文件大小不能超过 5MB!");
        return false;
      }
      this.$confirm("裁剪当前图片, 是否继续?", "提示", {
        confirmButtonText: "直接上传",
        cancelButtonText: "图片裁剪",
        closeOnClickModal: false,
        closeOnPressEscape: false,
        showClose: false,
        type: "warning",
      })
        .then(() => {
          //兼容ie
          this.$emit("update:file", files);
          this.$emit("change");
          //防止上传相同的图片  change事件不触发
          this.$refs["input"].value = null;
        })
        .catch(() => {
          // 上传成功后将图片地址赋值给裁剪框显示图片
          this.$nextTick(() => {
            this.option.img = window.URL.createObjectURL(files);
            this.dialogVisible = true;
          });
        });
    },
    //删除图片
    deleteImg() {
      this.$confirm("删除该图片, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$emit("deleteImg");
        })
        .catch(() => { });
    },
    //二次编辑图片
    editAgain() {
      //没有上传  而是直接二次编辑
      if (!this.option.img) {
        // 方众的url直接转成base64即可
        if (this.picUrl.includes("res.gts56.com")) {
          let image = new Image();
          // 处理缓存
          image.src = this.picUrl + "?v=" + Math.random();
          // 支持跨域图片
          image.crossOrigin = "*";
          image.onload = () => {
            let base64 = this.transBase64FromImage(image);
            this.option.img = base64;
          };
          this.dialogVisible = true;
        } else {
          //图片不是方众的  需要上传到方众的oss  防止出现跨域情况
          upLoadImgByUrl({ FileUrl: this.picUrl, FileType: this.fileType })
            .then((res) => {
              let image = new Image();
              // 处理缓存
              image.src = res.data + "?v=" + Math.random();
              // 支持跨域图片
              image.crossOrigin = "*";
              image.onload = () => {
                let base64 = this.transBase64FromImage(image);
                this.option.img = base64;
              };
              this.dialogVisible = true;
            })
            .finally(() => {
              this.dialogVisible = true;
            });
        }
      } else {
        this.dialogVisible = true;
      }
    },
    // 将网络图片转换成base64格式
    transBase64FromImage(image) {
      let canvas = document.createElement("canvas");
      canvas.width = image.width;
      canvas.height = image.height;
      let ctx = canvas.getContext("2d");
      ctx.drawImage(image, 0, 0, image.width, image.height);
      // 可选其他值 image/jpeg
      return canvas.toDataURL("image/jpeg", 1);
    },
  },
  watch: {
    dialogVisible(newVal, oldVal) {
      console.log(newVal, "==");
      if (!newVal) {
        this.dialogVisible = false;
        this.isSubmit = false;
        this.submitIcon = "el-icon-upload";
        this.submitText = "上传图片";
        this.$refs["input"].value = null;
        this.option.img = "";
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/style/variable.scss";

.uploadBox {
  width: 280px;
  height: 180px;
  border-radius: 5px;
  cursor: pointer;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $textGray2;
  position: relative;



  .el-image {
    border: 1px dotted;
  }

  .cover1 {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    box-sizing: border-box;
    background: rgb(251, 253, 255);

    i {
      font-size: 30px;
    }

    img {
      width: 100%;
      height: 100%;
    }

    span:last-child {
      color: $textGray1;
      font-size: 13px;
    }
  }

  .cover2 {
    position: absolute;
    font-size: 50px;

    .img {
      width: 100%;
      height: 100%;
    }
  }

  .delete {
    position: absolute;
    right: 5px;
    bottom: 5px;
    font-size: 25px;
    padding: 5px;
    border-radius: 50%;
    border: 1px solid black;
    color: black;
    z-index: 1;
  }

  .edit {
    position: absolute;
    right: 55px;
    bottom: 5px;
    font-size: 25px;
    padding: 5px;
    border-radius: 50%;
    border: 1px solid black;
    color: black;
    z-index: 1;
  }
}
</style>
<style lang="scss">
.roate {
  width: 120px;
  margin: 0px 10px;

  .el-input--suffix .el-input__inner {
    width: 120px;
    padding: 0px 10px;
  }
}
</style>
